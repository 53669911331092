import { CheckIcon, ListPlusIcon, PlusIcon } from "lucide-react";
import {
  RefObject,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Input } from "../ui/input";
import { createId } from "@paralleldrive/cuid2";
import { ChevronsUpDown } from "lucide-react";

export type LabelAndValuesType = { label: string; value: string };

interface InputSearchAndDropdownProps {
  list: LabelAndValuesType[];
  selectedListValues: LabelAndValuesType[];
  setSelectedListValues: Dispatch<SetStateAction<LabelAndValuesType[]>>;
}

export const InputSearchAndDropdown = ({
  list,
  selectedListValues,
  setSelectedListValues,
}: InputSearchAndDropdownProps) => {
  const [masterList, setMasterList] = useState(list);
  const [filteredItems, setFilteredItems] = useState(list);
  const [listDropdownOpen, setListDropdownOpen] = useState(true);
  const [search, setSearch] = useState("");

  const handleSelectListValue = (item: LabelAndValuesType) => {
    const selectedValues = selectedListValues.map((lv) => lv.value);
    if (selectedValues.includes(item.value)) {
      setSelectedListValues(
        selectedListValues.filter((lv) => lv.value !== item.value),
      );
    } else {
      setSelectedListValues([...selectedListValues, item]);
    }
  };

  const createAndAddNewValue = (label: string) => {
    const newValue = createId();
    const newItem = { label: label, value: newValue };

    setMasterList([...masterList, newItem]);
    handleSelectListValue(newItem);
    setSearch("");
  };

  const handleRemovePill = (value: string) => {
    setSelectedListValues(
      selectedListValues.filter((lv) => lv.value !== value),
    );
  };

  const isValueSelected = (value: string) => {
    const selectedValues = selectedListValues.map((lv) => lv.value);
    return selectedValues.includes(value);
  };

  const handleSearch = (searchStr: string) => {
    setSearch(searchStr);
    const matchesSearchQuery = masterList.filter((lv) =>
      lv.label.toLowerCase().includes(searchStr.toLowerCase()),
    );
    setFilteredItems(matchesSearchQuery);
  };

  const wrapperRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, () => setListDropdownOpen(false));

  return (
    <div>
      <div className="flex flex-wrap items-center gap-2">
        <div className="relative w-full">
          <ListPlusIcon className="absolute inset-y-1/2 left-2 h-4 w-4 -translate-y-1/2" />
          <Input
            placeholder="Search lists or create new list..."
            className="pl-6"
            onClick={() => setListDropdownOpen(!listDropdownOpen)}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            onKeyUp={(e) => {
              search && e.key === "Enter"
                ? createAndAddNewValue(search)
                : undefined;
            }}
          />
          <ChevronsUpDown className="absolute inset-y-1/2 right-2 h-4 w-4 -translate-y-1/2" />
        </div>
        {selectedListValues.map((lv) => (
          <div
            key={lv.value}
            className="flex items-center gap-1 rounded-full bg-blue-100 px-2 py-1 text-blue-700"
          >
            {masterList.find((lv_) => lv_.value === lv.value)?.label}
            <button
              onClick={() => handleRemovePill(lv.value)}
              className="text-blue-700 hover:text-blue-900"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <div className="relative w-full text-sm" ref={wrapperRef}>
        {listDropdownOpen && (
          <div className="absolute left-0 top-2 max-h-48 w-full overflow-y-auto rounded-md border border-gray-200 bg-white p-2 shadow-md">
            {filteredItems.map((lv) => (
              <div
                key={lv.value}
                className="flex items-center gap-1 py-1  hover:bg-slate-50"
                onClick={() => handleSelectListValue(lv)}
              >
                {isValueSelected(lv.value) && <CheckIcon className="h-3 w-3" />}
                {lv.label}
              </div>
            ))}
            {!search && filteredItems.length === 0 && (
              <div>
                <i>
                  No lists created yet. Enter a new list name to create one.
                </i>
              </div>
            )}
            {search && (
              <div
                className="flex items-center gap-1 py-1 hover:bg-slate-50"
                onClick={() => createAndAddNewValue(search)}
                tabIndex={0}
              >
                <PlusIcon className="h-3 w-3" /> Create list <i>{search}</i>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function useOutsideAlerter(
  ref: RefObject<HTMLElement>,
  onOutsideClick: () => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}
