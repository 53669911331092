import { useMutation, useQuery } from "@tanstack/react-query";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { z } from "zod";
import { FBQ, Mixpanel } from "~/components/tracking-provider";
import { env } from "~/env.mjs";
import type {
  OutfitSearchParams,
  OutfitsListParams,
  ProductVersatilityParams,
} from "~/lib/api";
import {
  DerivedFeaturesSchema,
  getItemsFromUserUpload,
  VariantsSchema,
  getOutfit,
  getOutfitsList,
  getProductVersatility,
  getSearchOutfits,
} from "~/lib/api";
import { api, type RouterInputs } from "~/utils/api";
import { isValidNumber } from "./utils";

export const useOutfitsList = (params: OutfitsListParams) => {
  return useQuery({
    queryKey: [
      "outfits",
      params.page,
      params.limit,
      params.gender,
      params.source,
    ],
    queryFn: async () => getOutfitsList(params),
  });
};

export const useOutfitsSearch = (params: OutfitSearchParams) => {
  return useQuery({
    queryKey: [
      "outfitsSearch",
      params.query,
      params.limit,
      params.num_candidates,
      params.gender,
    ],
    queryFn: async () => getSearchOutfits(params),
  });
};

export const useOutfit = (outfitId: string) => {
  return useQuery({
    queryKey: ["outfit", outfitId],
    queryFn: async () => getOutfit(outfitId),
    enabled: !!outfitId,
  });
};

export const useManyOutfits = (outfitIds: string[]) => {
  return useQuery({
    queryKey: ["outfits", outfitIds],
    queryFn: async () => {
      const outfits = await Promise.all(outfitIds.map((id) => getOutfit(id)));
      return outfits;
    },
    enabled: !!outfitIds.length,
  });
};

export const ShopProductSchema = z.object({
  id: z.string(),
  url: z.string(),
  title: z.string(),
  price: z.number().default(0),
  currency_price: z.object({
    USD: z.number(),
    EUR: z.number(),
    GBP: z.number(),
  }),
  derived_features: DerivedFeaturesSchema,
  images: z.array(
    z.object({
      src: z.string(),
    }),
  ),
  variants: z.array(VariantsSchema),
  max_variant_discount: z
    .object({
      percent: z.number(),
      absolute: z.number(),
    })
    .nullish(),
});
export type ShopProduct = z.infer<typeof ShopProductSchema>;

export const ShopProductsSchema = z.array(ShopProductSchema);

export interface SearchProductsParams {
  search_type: "TEXT" | "OUTFIT_PRODUCT" | "PRODUCT" | "USER_IMAGE";
  query: string;
  gender: "MALE" | "FEMALE" | "UNISEX";
  new_or_used: "ALL" | "NEW" | "USED";
  colour_weight: number;
  limit: number;
  num_candidates: number;
  currency: "GBP" | "EUR" | "USD";
  lowerPriceRange: number;
  upperPriceRange: number;
  brands?: string[];
  colours?: string[];
  patterns?: string[];
  shops?: string[];
  productTypes?: string[];
}

export const useSearchProducts = (params: SearchProductsParams) => {
  return useQuery({
    queryKey: ["products", params],
    queryFn: async () => {
      const url = new URL(env.NEXT_PUBLIC_API_URL + "/product/search");
      if (params) {
        url.searchParams.set("search_type", params.search_type);
        url.searchParams.set("query", params.query ?? "");
        url.searchParams.set("gender", params.gender);
        url.searchParams.set("new_or_used", params.new_or_used);
        url.searchParams.set("colour_weight", params.colour_weight.toString());
        url.searchParams.set("limit", params.limit.toString());
        url.searchParams.set(
          "num_candidates",
          params.num_candidates.toString(),
        );
        url.searchParams.set("currency", params.currency);
        if (params.brands)
          url.searchParams.set("brands", params.brands.join(";"));

        if (params.colours)
          url.searchParams.set("colors", params.colours.join(";"));

        if (params.patterns)
          url.searchParams.set("patterns", params.patterns.join(";"));

        if (params.shops) url.searchParams.set("shops", params.shops.join(";"));

        if (params.productTypes)
          url.searchParams.set("productTypes", params.productTypes.join(";"));

        if (
          isValidNumber(params.lowerPriceRange) &&
          isValidNumber(params.upperPriceRange)
        ) {
          url.searchParams.set(
            "lowerPriceRange",
            params.lowerPriceRange.toString(),
          );
        }
        if (params.upperPriceRange) {
          url.searchParams.set(
            "upperPriceRange",
            params.upperPriceRange.toString(),
          );
        }
      }

      const response = await fetch(url.toString());
      const data = (await response.json()) as unknown;
      return ShopProductsSchema.parse(data);
    },
    enabled: !!params.query,
  });
};

export const useProduct = (productId: string) => {
  return useQuery({
    queryKey: ["product", productId],
    queryFn: async () => {
      const res = await fetch(
        env.NEXT_PUBLIC_API_URL + `/product/${productId}`,
      );
      if (!res.ok) throw new Error("Error fetching product");
      const data = (await res.json()) as unknown;
      return ShopProductSchema.parse(data);
    },
    enabled: !!productId,
  });
};

export const useProductVersatileOutfits = (
  params: ProductVersatilityParams,
) => {
  return useQuery({
    queryKey: ["productVersatileOutfits", params.product_id, params.gender],
    queryFn: async () => getProductVersatility(params),
    enabled: !!params.product_id,
  });
};

interface SignUpParams {
  name: string;
  email: string;
  source: string;
}

interface SignUpConfig {
  callbackUrl?: string;
  redirect?: boolean;
}

export const useSignUp = (config: SignUpConfig) => {
  const signUp = api.user.signUp.useMutation();

  const mutation = useMutation({
    mutationFn: async (params: SignUpParams) => {
      const eventId = crypto.randomUUID();
      Mixpanel.track("Sign Up", params);
      FBQ.track("Lead", {}, { eventID: eventId });

      console.log("Sign up", params);

      await signUp.mutateAsync({
        eventId: eventId,
        email: params.email,
        name: params.name,
      });

      const res = await signIn("email", {
        email: params.email,
        callbackUrl: config.callbackUrl,
        redirect: config.redirect,
      });
      if (res?.error) throw new Error(res.error);
      return res;
    },
    onError: (error) => {
      console.error("Error signing up:", error);
    },
  });

  return mutation;
};

export const useUserCountry = () => {
  return useQuery({
    queryKey: ["userCountry"],
    queryFn: async () => {
      const res = await fetch("/api/country");
      console.log("res:", res.ok);
      if (!res.ok) return "";
      const data = (await res.json()) as { country: string };
      console.log("Country:", data.country);
      return data.country;
    },
  });
};

export const useUserImage = (userImageId: string) => {
  return useQuery({
    queryKey: ["userImageUpload", userImageId],
    queryFn: async () => getItemsFromUserUpload(userImageId),
    enabled: !!userImageId,
  });
};
