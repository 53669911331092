import { z } from "zod";
import { env } from "~/env.mjs";

type FetchGetParams = Record<string, string | number | null | undefined>;

const fetchGET = async (route: string, params: FetchGetParams = {}) => {
  const url = new URL(env.NEXT_PUBLIC_API_URL + route);
  for (const key in params) {
    const value = params[key];
    if (value === undefined) continue;
    if (value === null) url.searchParams.set(key, "");
    else url.searchParams.set(key, value.toString());
  }
  const response = await fetch(url.toString());
  if (!response.ok) throw new Error(`Error fetching ${route}`);
  return (await response.json()) as unknown;
};

const fetchPOST = async (route: string, body: Record<string, unknown>) => {
  const url = new URL(env.NEXT_PUBLIC_API_URL + route);
  const response = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  if (!response.ok) throw new Error(`Error fetching ${route}`);
  return (await response.json()) as unknown;
};

const OutfitSchema = z.object({
  id: z.string(),
  image_url: z.string(),
});
export type Outfit = z.infer<typeof OutfitSchema>;

const OutfitsSchema = z.array(OutfitSchema);

const OutfitsPaginatedSchema = z.object({
  outfits: z.array(OutfitSchema),
  pages: z.number(),
});
export type OutfitsPaginatedResponse = z.infer<typeof OutfitsPaginatedSchema>;

export interface OutfitsListParams {
  page: number;
  limit: number;
  gender?: "MALE" | "FEMALE";
  source?: "generated";
  ids?: string;
}

export const getOutfitsList = async (params: OutfitsListParams) => {
  const data = await fetchGET("/outfit/list", { ...params });
  return OutfitsPaginatedSchema.parse(data);
};

export interface OutfitsForLandingPageParams {
  page: number;
  limit: number;
  gender?: "MALE" | "FEMALE";
  source?: "generated";
  dress_code?: string;
}

export const getOutfitsForLandingPage = async (
  params: OutfitsForLandingPageParams,
) => {
  const data = await fetchGET("/outfit/list-outfits-for-landing", {
    ...params,
  });
  return OutfitsPaginatedSchema.parse(data);
};

type Gender = "MALE" | "FEMALE" | "UNISEX";

export interface OutfitSearchParams {
  query: string;
  limit: number;
  num_candidates: number;
  gender: Gender;
}

export const getSearchOutfits = async (params: OutfitSearchParams) => {
  const data = await fetchGET("/outfit/search", { ...params });
  return OutfitsSchema.parse(data);
};

export interface ProductVersatilityParams {
  product_id: string;
  gender: Gender;
  limit?: number;
}

export const getProductVersatility = async (
  params: ProductVersatilityParams,
) => {
  const data = await fetchGET("/outfit/versatility-search", { ...params });
  return OutfitsSchema.parse(data);
};

export const DerivedFeaturesSchema = z.object({
  colour: z.string().nullish(),
  pattern: z.string().nullish(),
  wearing_style: z.string().nullish(),
  material: z.string().nullish(),
  clothing_type: z.string().nullish(),
  price_category: z.string().nullish(),
});

export type DerivedFeatures = z.infer<typeof DerivedFeaturesSchema>;

const boundingBoxTransform = (v: number[]) => {
  const [x1 = 0, y1 = 0, x2 = 0, y2 = 0] = v;
  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  };
};
const PriceObj = z.object({
  price: z.number(),
  USD: z.number(),
  EUR: z.number(),
  GBP: z.number(),
});
export type CurrencyPrice = z.infer<typeof PriceObj>;

export const VariantsSchema = z.object({
  id: z.number(),
  title: z.string(),
  option1: z.string().nullable(),
  option2: z.string().nullable(),
  option3: z.string().nullable(),
  price: PriceObj,
  available: z.boolean(),
  compare_at_price: PriceObj.nullable(),
});

export type Variants = z.infer<typeof VariantsSchema>;

const OutfitProduct = z.object({
  id: z.string(),
  derived_features: DerivedFeaturesSchema.nullable(),
  bounding_box: z.array(z.number()).transform(boundingBoxTransform),
  similar_products: z
    .array(
      z.object({
        price_range: z.string(),
        product_id: z.string(),
        price: PriceObj,
      }),
    )
    .optional(),
});
export type OutfitProduct = z.infer<typeof OutfitProduct>;

const OutfitWithProductsSchema = z.object({
  id: z.string(),
  image_url: z.string(),
  faceswap_image_url: z.string().optional(),
  created_for_user_id: z.string().optional(),
  products: z.array(OutfitProduct),
});

export type OutfitWithProductsResponse = z.input<
  typeof OutfitWithProductsSchema
>;
export type OutfitWithProducts = z.infer<typeof OutfitWithProductsSchema>;

export const getOutfit = async (id: string) => {
  const data = await fetchGET(`/outfit/${id}`);
  return OutfitWithProductsSchema.parse(data);
};

const InitialOutfitsSchema = z.array(
  z.object({
    dress_code: z.string(),
    outfit_ids: z.array(z.string()),
  }),
);
export type InitialOutfitsResponse = z.infer<typeof InitialOutfitsSchema>;

export const getInitialThemes = async (userId: string) => {
  const data = await fetchGET("/user/get-initial-outfits", { _id: userId });
  const parsed = InitialOutfitsSchema.parse(data);
  return parsed.map((theme) => ({
    id: theme.dress_code,
    title: theme.dress_code,
    outfit_ids: theme.outfit_ids,
  }));
};

const OnboardingOutfitsSchema = z.array(
  z.object({
    rationale: z.array(z.string()),
    outfit_occasions: z.array(z.string()),
    outfit_with_products: OutfitWithProductsSchema,
  }),
);
export type OnboardingOutfitsResponse = z.input<typeof OnboardingOutfitsSchema>;

export const getOnboardingOutfits = async (userId: string) => {
  const data = await fetchGET(`/user/get-onboarding-outfits`, { _id: userId });
  return OnboardingOutfitsSchema.parse(data);
};

const UserImageSchema = z.object({
  image_url: z.string(),
  products: z
    .array(
      z.object({
        id: z.string(),
        derived_features: DerivedFeaturesSchema.nullable(),
        bounding_box: z.array(z.number()).transform(boundingBoxTransform),
      }),
    )
    .optional(),
  pipe_stage: z.string(),
});

export const getItemsFromUserUpload = async (id: string) => {
  const data = await fetchGET(`/ml/segment/${id}`);
  return UserImageSchema.parse(data);
};

export async function createTextEmbedding(text: string) {
  return (await fetchPOST("/ml/embed/text", { text })) as number[];
}
