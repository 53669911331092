type KeyFunc<T, K extends string> = (item: T, index?: number) => K;
type ValueFunc<T, V> = (item: T, index?: number) => V;

export function indexByKey<T, K extends string>(
  array: T[],
  keyFunc: KeyFunc<T, K>,
): Map<K, T> {
  const result = new Map<K, T>();
  array.forEach((element, index) => {
    const key = keyFunc(element, index);
    result.set(key, element);
  });
  return result;
}

export function toMap<T, K extends string, V>(
  array: T[],
  keyFunc: KeyFunc<T, K>,
  valueFunc: ValueFunc<T, V>,
): Map<K, V> {
  const result = new Map<K, V>();
  array.forEach((element, index) => {
    const key = keyFunc(element, index);
    result.set(key, valueFunc(element, index));
  });
  return result;
}

export function toMapGrouped<T, K extends string, V>(
  array: T[],
  keyFunc: KeyFunc<T, K>,
  valueFunc: ValueFunc<T, V>,
): Map<K, V[]> {
  const result = new Map<K, V[]>();
  array.forEach((element, index) => {
    const key = keyFunc(element, index);
    const value = valueFunc(element, index);
    const existing = result.get(key) ?? [];
    result.set(key, [...existing, value]);
  });
  return result;
}
